import React from 'react';
import logoModer from './components/layout/logoModer.png';

export default class AppNoAvailable extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px', padding: '20px' }}>
        <img alt='logotip' src={logoModer} />
        <br />
        <br />
        <h2>Aplikacija ni več v uporabi.</h2>
        <br />
      </div>
    );
  }
}
