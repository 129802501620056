import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';

const loginError = authContext.getLoginError();
const loginWasTriedButFailed =
  loginError !== undefined && loginError !== null && loginError !== '';
const DO_NOT_LOGIN = false;

require('./indexAppNoAvailable');

/*
if (loginWasTriedButFailed === true) {
  require("./indexAppError.js");
} else {
  runWithAdal(
    authContext,
    () => {
      require("./indexApp.js");
    },
    DO_NOT_LOGIN
  );
}

*/
