import { AuthenticationContext } from "react-adal";

export const adalSettings = {
  tenant: "adfs",
  cacheLocation: "localStorage",
  instance:        process.env.REACT_APP_ADFS_Instance,
  redirectUrl:     process.env.REACT_APP_ADFS_RedirectUrl,
  clientId:        process.env.REACT_APP_ADFS_ClientId,
  endpoints: {
      api:         process.env.REACT_APP_ADFS_ClientId
  }
};

export const getApiDomain = () => process.env.REACT_APP_ApiDomain;

export const getSettings = () => adalSettings;

export const authContext = new AuthenticationContext(getSettings());

export const getUserName = () => {
  let getUserName = authContext.getCachedUser();
  return getUserName.userName;
};

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

export const getRequest = () => {
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return request;
};

export const postRequest = (body) => {
  const request = {
    method: "POST",
    body: body,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  return request;
};

export const logoutFromApp = () => {
  authContext.logOut();
};

export const checkErorr = () => {
  console.log(authContext.getLoginError());
};
